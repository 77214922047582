import * as React from 'react';
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';

import ReactAudioPlayer from 'react-audio-player';

import {
		getUserBettedNumber,
		queryUserBettedRound,
		getUserRoundStatus,
	
		getUserRoundInfo,
		queryBonus
} from '../../utils/contractUtils'

import {getAddress} from '../../utils/tool'


export const MyBetBox = React.forwardRef(({currentRound,userBetTotal,getCurrentMyBetAmount},ref) => {
	const { t } = useTranslation();
	
	const [myBetLoading,setMyBetLoading] = React.useState(false);//MyBetLoading
	const [currentBettedList,setCurrentBettedList] = React.useState([]);//CurrentBettedList
	const [lastBettedList,setLastBettedList] = React.useState([]);//LastBettedList
	const [lastIsWin,setLastIsWin] = React.useState('0');//LastIsWin  2(win) 1(lost) 0(Not participating)
	
	const [historyPopup,setHistoryPopup] = React.useState(false);
	const [winPopup,setWinPopup] = React.useState(false);
	const [bettedList,setBettedList] = React.useState([]);
	const [loading,setLoading] = React.useState(false);
	
	const [page,setPage] = React.useState(0);
	const [size,setSize] = React.useState(8);
	
	const audioRef = React.useRef(null);
	
	React.useImperativeHandle(ref, () => ({
		getPastBets: getPastBets,
		getPreviousIsWin:getPreviousIsWin,
		getMyBet:getMyBet
	}))
	
	const getPastBets = (current) =>{
		if(myBetLoading){
			return;
		}
		setMyBetLoading(true);
		return new Promise((resolve, reject) => {
			Promise.all([
				getUserBettedNumber(getAddress(),Number(current)+1),
			]).then(res => {
				if(res[0]){
					setCurrentBettedList([...res[0]]);
				}
				setMyBetLoading(false);
				resolve('getPastBets');
			}).catch(err => {
				setMyBetLoading(false);
				reject('getPastBets-error');
			})
		})
	}
	
	const getPreviousIsWin = (current,type) =>{
		return new Promise((resolve, reject) => {
			Promise.all([
				getUserRoundStatus(Number(current)),
			]).then(res => {
				if(res[0]){
					setLastIsWin(res[0].status);
					if(type=='timeEnd'){//Draw a prize
						if(res[0].status!='0'){
							audioRef_play();
							setWinPopup(true);
						}
					}
				}
				resolve('getPreviousIsWin');
			}).catch(err => {
				reject('getPreviousIsWin-error');
			})
		})
	}
	
	
	const getMyBet = () => {
		return new Promise((resolve, reject) => {
			Promise.all([
				queryUserBettedRound('0','30'),
			]).then(res => {
				if(res[0]){
					let arr = [];
					res[0].list.forEach((item,index)=>{
						arr.push({id:item,isLoading:false,hidden:true,list:[],listIsWin:[],isWin:res[0].result[index]});
					})
					setBettedList([...bettedList,...arr]);
				}
				setHistoryPopup(true);
				setLoading(false);
				resolve('getMyBet');
			}).catch(err => {
				setLoading(false);
				reject('getMyBet-error')
			})
		})
	}
	
	
	const showDetails = (item,index) => {
		let bettedArr = [...bettedList];
		if(!item.hidden){
			bettedArr[index].hidden = true;
			setBettedList(bettedArr);
			return;
		}
		if(item.list.length>0){
			bettedArr[index].hidden = false;
			bettedArr[index].isLoading = false;
			setBettedList(bettedArr);
			return;
		}else{
			bettedArr[index].isLoading = true;
			setBettedList(bettedArr);
		}
		return new Promise((resolve, reject) => {
			Promise.all([
				getUserBettedNumber(getAddress(),item.id),
			]).then(res => {
				let arr = [...bettedList];
				if(res){
					if(res[0]){
						arr[index].list = res[0];
					}
					arr[index].hidden = false;
					arr[index].isLoading = false;
					setBettedList(arr);
					resolve('showDetails');
				}
			}).catch(err => {
				let arr = [...bettedList];
				arr[index].isLoading = false;
				setBettedList(arr);
				reject('showDetails-error')
			})
		})
	}
	
	const audioRef_play = ()=>{
		audioRef.current.audioEl.current.play();
	}
	
	React.useEffect(() => {
		
	}, [])
	  
    return (
		<Box sx={{
			fontSize:{
				xs:'0.8rem',
				sm:'0.9rem',
				md:'1rem'
			},
		}}>
			<ReactAudioPlayer
				src={`../../${lastIsWin=='2'?'win':'lost'}.mp3`}
				autoPlay={false}
				loop={false}
				ref={audioRef}
			/>
			<Box sx={{
				position:'relative'
			}}>
				<Box
					component="img"
					sx={{
					  width: {
						  xs:'90%',
						  sm:'50%'
					  },
					  margin:'0 auto',
					  display:'block'
					}}
					alt=""
					src={`../images/scene/my-bet.png`}
					onClick={()=>{audioRef_play()}}
					/>
				<Box sx={{
					position:'absolute',
					top:'22%',
					left:'20%',
					right:'20%',
					bottom:'65%',
					textAlign:'center',
					color:'rgba(142, 57, 4, 1)',
					fontWeight:'900'
				}}>
					<Box className="ZooBlackFont">{t('game.MyBet')}</Box>
					<Box>
						{t('game.CurrentRound')} #{currentRound!='-'?Number(currentRound)+1:'-'}
					</Box>
				</Box>	
				<Box sx={{
					position:'absolute',
					top:'35%',
					left:'20%',
					right:'20%',
					bottom:setLastBettedList.length==0?'18%':'25%',
					textAlign:'center',
					overflowY: 'auto',
					color:'rgba(23, 23, 23, 1)',
					fontWeight:400
				}}>
					{
						currentBettedList.map((item,index)=>{
							return (
								<Box key={index} sx={{mt:1}} className="flex">
									{
										['AnimalNo_1','AnimalNo_2','AnimalNo_3'].map((AnimalNo,AnimalNoIndex)=>{
											return (
												<Box key={AnimalNo} sx={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													width:{xs:'2rem',sm:'2.5rem'},
													height:{xs:'2rem',sm:'2.5rem'},
													background:`url(../images/animal/-.png) no-repeat center`,
													backgroundSize: "contain",
													mr:0.5,
													color:'rgba(77, 25, 9, 1)'
												}}>
													{item[AnimalNoIndex]=='10'?('N'):(
														<Box
															component="img"
															sx={{
															  width: '70%',
															}}
															alt=""
															src={`../images/animal/${item[AnimalNoIndex]}.png`}
														/>
													)}
												</Box>
											)
										})
									}
									<span>(M{Number(item[4])+1})</span>
									<span>×{item[3]}</span>
								</Box>
							)
						})
					}
					{
						currentBettedList.length==0?(
							<Box sx={{color:'rgba(142, 57, 4, 1)',fontSize:'24px',mt:5}}>{t('game.NoBet')}</Box>
						):('')
					}
				</Box>
				<Box className='flex' sx={{
					position:'absolute',
					top:'84%',
					left:'20%',
					right:'20%',
					bottom:'10%',
					textAlign:'center',
					color:'rgba(255, 235, 59, 1)',
					fontWeight:400,
					cursor: 'pointer',
					alignItem:'flex-start',
					display:lastIsWin!='0'?'flex':'none'
				}} onClick={()=>{
					getMyBet();
				}}>
					#{currentRound} 
					<Box sx={{
						ml:1,
						background:lastIsWin=='2'?'rgba(255, 235, 59, 1)':'#aaa',
						height:'1.3rem',
						lineHeight:'1.3rem',
						px:2,
						textAlign:'center',
						borderRadius:'10px',
						color:lastIsWin=='2'?'rgba(142, 57, 4, 1)':'#fff',
						fontSize:'0.7rem'
					}}>
						{lastIsWin=='2'?t('game.winningThelottery'):t('game.notWin')}
					</Box>
				</Box>
			</Box>
			
			<Dialog
					open={historyPopup}
					onClose={()=>{
						setHistoryPopup(false);
						setBettedList([]);
					}}
					fullWidth={true}
					maxWidth="xs"
					className="BetPopup"
				  >
				  <DialogContent sx={{
						background:`url(../images/scene/chopse-animal.png) no-repeat center`,
						backgroundSize: "100% 100%",
						position:'relative',
				   }}>
						<Box sx={{
							padding:'2rem 0.5rem 0.5rem',
							display:'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
							<Box sx={{color:'#fff'}}>{t('game.MyHistoryBet')}</Box>
							<Box
								component="img"
								sx={{
									height:'3rem',
									cursor:'pointer',
									position:'absolute',
									top:'0',
									right:'0'
								}} 
								onClick={()=>{setHistoryPopup(false);setBettedList([]);}}
								alt=""
								src="../images/scene/close-x.png"/>
						</Box>
						<Box className='scrollArea' sx={{
							minHeight:'15rem',
							maxHeight:'15rem',
							padding:0.5,
							px:2,
							mb:4,
							overflowY:'auto',
						}}>
							{!loading?bettedList.map((item,index)=>{
								return (
									<Box key={index}>
										<Box  sx={{
											mt:1,
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											fontWeight:400,
											fontSize:{
												xs:'1rem',
												sm:'1.2rem'
											}
											}}>
											<Box sx={{
												display: 'flex',
												alignItems: 'center'
											}}>
												#{item.id}
												<Box hidden={Number(currentRound)+1 == item.id} sx={{
													ml:1,
													background:item.isWin?'rgba(255, 235, 59, 1)':'#aaa',
													height:'1.3rem',
													lineHeight:'1.3rem',
													px:2,
													textAlign:'center',
													borderRadius:'10px',
													color:item.isWin?'rgba(142, 57, 4, 1)':'#fff',
													fontSize:'0.7rem'
												}}>
													{item.isWin?t('game.winningThelottery'):t('game.notWin')}
												</Box>
											</Box>
											{
												item.isLoading?(<CircularProgress size={20} color="inherit"/>):(
													<Box
														component="img"
														sx={{
														  width: '1.5rem',
														  cursor:'pointer',
														}}
														src={`../images/body/${item.hidden?'Details':'putAway'}.png`}
														onClick={()=>{showDetails(item,index)}}
													/>
												)
											}
											
											
											
										</Box>
										<Box hidden={item.hidden}>
											<Box sx={{
												display:'grid',
												gridTemplateColumns: 'repeat(2, 1fr)',
												gap: 1,
												p:1
											}}>
												{
													item.list&&item.list.map((item1,index1)=>{
														return (
															<Box key={index1} className="flex">
																{
																	['AnimalNo_1','AnimalNo_2','AnimalNo_3'].map((AnimalNo,AnimalNoIndex)=>{
																		return (
																			<Box key={AnimalNo} sx={{
																				display: 'flex',
																				alignItems: 'center',
																				justifyContent: 'center',
																				width:{xs:'1.5rem',sm:'2rem'},
																				height:{xs:'1.5rem',sm:'2rem'},
																				background:`url(../images/animal/-.png) no-repeat center`,
																				backgroundSize: "contain",
																				mr:0.5
																			}}>
																				{item1[AnimalNoIndex]=='10'?('N'):(
																					<Box
																						component="img"
																						sx={{
																						  width: '70%',
																						}}
																						alt=""
																						src={`../images/animal/${item1[AnimalNoIndex]}.png`}
																					/>
																				)}
																			</Box>
																		)
																	})
																}
																<span>(M{Number(item1[4])+1})</span>
																<span>×{item1[3]}</span>
															</Box>
														)
													})
												}
												
											</Box>
										</Box>
									</Box>
								)
							}):(<CircularProgress color="inherit" className="positionCenter" sx={{left:'44% !important'}}/>)}
						</Box>
				</DialogContent>
			</Dialog>
			
			<Dialog
					open={winPopup}
					onClose={()=>{
						setWinPopup(false)
					}}
					fullWidth={true}
					maxWidth="sm"
					className="winPopup"
				  >
				  <DialogContent>
						<Box sx={{
							position:'relative',
						}}  onClick={()=>{setWinPopup(false)}}>
							<Box
								component="img"
								sx={{
								  position:'absolute',
								  width:'12rem',
								  top:'-50%',
								  left:'50%',
								  transform: 'translateX(-50%)',
								}}
								alt=""
								src={`../images/body/star${lastIsWin==2?'':'-dark'}.png`}
							/>
							<Box
								component="img"
								sx={{
								  position:'absolute',
								  width:'100%',
								  top:'50%',
								  transform: 'translateY(-50%)',
								}}
								alt=""
								src={`../images/body/blast${lastIsWin==2?'':'-dark'}.png`}
							/>
							<Box
								className="turnAround"
								component="img"
								sx={{
								  position:'absolute',
								  width:'400px',
								  height:'400px',
								  top:'50%',
								  left:'50%',
								  marginTop:'-200px',
								  marginLeft:'-200px',
								  zIndex:'-1'
								}}
								alt=""
								src={`../images/body/halo${lastIsWin==2?'':'-dark'}.png`}
							/>
							<Box sx={{
								background:lastIsWin==2?`rgba(165, 119, 72, 1)`:'#808080',
								border: lastIsWin==2?'10px solid rgba(142, 57, 4, 0.5)':'10px solid #9a9a9a',   
								borderRadius: {
									xs:'30px',
								},
								color:'#fff',
								textAlign:'center',
								py:5,
								maxWidth:'400px',
								margin:'0 auto'
							}}>
								{lastIsWin=='2'?t('game.Win'):t('game.Lost')}
							</Box>
						</Box>
				   </DialogContent>
			</Dialog>
			
		</Box>
    );
});

