import Message from '../components/Message';
import ReactDOM from 'react-dom'

const message = {
    dom: null,
    success({content, duration}) {
        this.dom = document.createElement('div');
		let className = `MESSAGE_${Math.ceil(Math.random()*1000)}`;
		this.dom.className = className;
        const JSXdom = (<Message content={content} duration={duration} boxClass={className} type='success'></Message>);
        ReactDOM.render(JSXdom,this.dom)
        document.body.appendChild(this.dom);
    },
    error({content, duration}) {
        this.dom = document.createElement('div');
		let className = `MESSAGE_${Math.ceil(Math.random()*1000)}`;
		this.dom.className = className;
        const JSXdom = (<Message content={content} duration={duration} boxClass={className} type='error'></Message>);
        ReactDOM.render(JSXdom,this.dom)
        document.body.appendChild(this.dom);
    },
    warning({content, duration}) {
        this.dom = document.createElement('div');
		let className = `MESSAGE_${Math.ceil(Math.random()*1000)}`;
		this.dom.className = className;
        const JSXdom = (<Message content={content} duration={duration} boxClass={className} type='warning'></Message>);
        ReactDOM.render(JSXdom,this.dom)
        document.body.appendChild(this.dom);
    },
    info({content, duration}) {
        this.dom = document.createElement('div');
		let className = `MESSAGE_${Math.ceil(Math.random()*1000)}`;
		this.dom.className = className;
        const JSXdom = (<Message content={content} duration={duration} boxClass={className} type='info'></Message>);
        ReactDOM.render(JSXdom,this.dom)
        document.body.appendChild(this.dom);
    }
};
 
export default message;