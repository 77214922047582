import * as React from 'react';
import Web3 from 'web3'
import contractData from '../contract/contract.json'
const tokenAbi = contractData.abi
const tokenAddress = contractData.tokenAddress

const refAbi = contractData.refAbi
const refAddress = contractData.refAddress

export const getWeb3 = () => {
	return new Web3(window.ethereum);
}

export const createTokenContract = () => {
	let obj = null
	try {
		let web = getWeb3();
		obj = new web.eth.Contract(tokenAbi, tokenAddress)
	} catch (e) {
		debugger
	}
	return obj
}

export const createRefContract = () => {
	let obj = null
	try {
		let web = getWeb3();
		obj = new web.eth.Contract(refAbi, refAddress)
	} catch (e) {
		debugger
	}
	return obj
}

export const getGameStatus = () => {
	return new Promise((resolve, reject) => {
		createTokenContract().methods.gameStatus().call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}

export const getCurrentRound = () => {
	return new Promise((resolve, reject) => {
		createTokenContract().methods.currentRound().call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}

export const getEstimateNextRoundTime = () => {
	return new Promise((resolve, reject) => {
		createTokenContract().methods.getEstimateNextRoundTime().call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}

export const getGameResult = (round) => {
	return new Promise((resolve, reject) => {
		createTokenContract().methods.getGameResult(round).call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}

export const queryAllUnPaidBonus = (address) => {
	return new Promise((resolve, reject) => {
		createTokenContract().methods.queryAllUnPaidBonus(address).call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}

export const withdrawAllBonus = (address) => {
	return new Promise((resolve, reject) => {
		createTokenContract().methods.withdrawAllBonus(address).call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}

export const gameFeeConfig = () => {
	return new Promise((resolve, reject) => {
		createTokenContract().methods.gameFeeConfig().call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}

export const queryUserBettedRound = (cursor,size=5) => {
	return new Promise((resolve, reject) => {
		createTokenContract().methods.queryUserBettedRound(window.ethereum.selectedAddress,cursor,size).call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}

export const getGameResultList = (top) => {
	return new Promise((resolve, reject) => {
		createTokenContract().methods.getGameResultList(top).call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}

export const getTInviteeCount = () => {
	return new Promise((resolve, reject) => {
		createRefContract().methods.getTInviteeCount(window.ethereum.selectedAddress).call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}

export const getUserBettedNumber = (address, round)=> {
	return new Promise((resolve, reject) => {
		createTokenContract().methods.getUserBettedNumber(address, round).call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}

export const queryBonus = (round) => {
	return new Promise((resolve, reject) => {
		createTokenContract().methods.queryBonus(window.ethereum.selectedAddress, round).call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}

export const getUserRoundStatus = (round) => {
	return new Promise((resolve, reject) => {
		createTokenContract().methods.getUserRoundStatus(window.ethereum.selectedAddress, round).call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}


export const getUserRoundInfo = (epoch) => {
	return new Promise((resolve, reject) => {
		createTokenContract().methods.getUserRoundInfo(window.ethereum.selectedAddress,epoch).call((error, result) => {
			if (!error) {
				return resolve(result)
			} else {
				return reject(error)
			}
		})
	})
}

export const receipt = (hash) => {
	return new Promise((resolve, reject) => {
		getWeb3().eth.getTransactionReceipt(hash, (err, res) => {
			if (res) {
				return resolve(res)
			} else {
				return reject(err)
			}
		})
	})
}


export function toWei(val) {
	return getWeb3().utils.toWei(val || '0.005', 'ether')
}

export function fromWei(num, decimals) {
	if (!num) {
		return '--'
	} else if (!decimals) {
		return 'num'
	}
	if (decimals === '0') {
		return num.toString().split('.')[0]
	}
	num = num || 0
	decimals = decimals || 18
	let amount = num
	return decimalsFilter(amount, decimals)
}


function decimalsFilter(num, decimals) {
	if (!num) {
		return '--'
	} else if (!decimals) {
		return 'num'
	}
	let amount = num.toString().split('.')[0]
	decimals = Number(decimals)
	if (amount.length < decimals + 1) {
		let length = decimals + 1 - amount.length
		for (let i = 0; i < length; i++) {
			amount = '0' + amount
		}
	}
	amount = (amount.slice(0, amount.length - decimals) + '.' + amount.slice(amount.length - decimals)).replace(/0+$/g,
		"")
	if (amount[amount.length - 1] === '.') {
		return amount.split('.')[0]
	} else {
		return amount
	}
}
