import * as React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next'
import Web3 from 'web3'
import {getAddress} from '../../utils/tool'

import { useNavigate, useLocation} from "react-router-dom";

export default function ConnectWallet () {
	const { t } = useTranslation();
	const [currentAccountAddress,setCurrentAccountAddress] = React.useState('');
	
	const navigate = useNavigate();
	const locationRouter = useLocation();
	
	const handleNetworkChange = (id) => {
		if (id != Web3.utils.toHex(80001 )&& id != Web3.utils.toHex(137)&&locationRouter.pathname!='/') {
			navigate('/notConnected');
			return;
		}
		if (locationRouter.pathname == '/notConnected') {
			navigate('/main',{replace: true});
		} else {
			location.reload()
		}
	}
	
	const handleAccountsChange = (account) => {
		if (account?.length > 0) {
			setCurrentAccountAddress(account[0]);
			if (locationRouter.pathname == '/notConnected') {
				navigate('/main',{replace: true});
			} else {
				location.reload()
			}
		} else {
			setCurrentAccountAddress('')
			if(locationRouter.pathname!='/'){
				navigate('/notConnected');
			}
		}
	}

	async function connect () { 
		if (currentAccountAddress) {
			return
		}
		if (getAddress()) {
			if (window.ethereum) {
				if(window.ethereum.networkVersion=='80001'||window.ethereum.networkVersion=='137'){
					if (locationRouter.pathname == '/notConnected') {
						navigate('/main',{replace: true});
					}
					setCurrentAccountAddress(getAddress());
				}else{
					try {
					    await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: Web3.utils.toHex(137) }]
						});
						setCurrentAccountAddress(getAddress());
					} catch (err) {
						// This error code indicates that the chain has not been added to MetaMask
						if (err.code === 4902) {
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
								    {
										chainName: 'Polygon Mainnet',
										chainId: Web3.utils.toHex(137),
										nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
										rpcUrls: ['https://polygon-bor.publicnode.com']
								    }
								]
							});
							setCurrentAccountAddress(getAddress());
						}
					}
				}
			}
		} else {
			try {
				window.ethereum.request({
						method: 'eth_requestAccounts'
					})
					.then(res => {
						connect()
					}).catch(e => {
						
					})
			} catch {
				
			}
		}
	}
	
	React.useEffect(() => {
		setTimeout(()=>{
			if (window&&window.ethereum) {
				window.ethereum.on('chainChanged', handleNetworkChange);//Chain change
				window.ethereum.on('accountsChanged', handleAccountsChange)//User switching
				if (window.ethereum.networkVersion == '80001' || window.ethereum.networkVersion == '137' || window.ethereum.chainId == '80001' || window.ethereum.chainId == '137') {
					web3 = new Web3(window.ethereum)
					if(!window.ethereum.selectedAddress){
						if(window.ethereum.address){
							window.ethereum.networkVersion = window.ethereum.chainId;
							window.ethereum.selectedAddress = window.ethereum.address;
						}else{
							if(locationRouter.pathname!='/'){
								navigate('/notConnected');
							}
							return;
						}
					}
				} else {
					if(locationRouter.pathname!='/'){
						navigate('/notConnected');
					}
					return;
				}
				connect()
			}
		},300)
	}, [])
	  
    return (
		<Box sx={{
				py:0.5,
				px:{
					xs:1,
					md:2
				},
				borderRadius:'50px',
				bgcolor:'rgba(168, 5, 255, 0.5)',
				border:'4px solid rgba(255, 255, 255, 0.5)',
				color:'#fff',
				display:'flex',
				cursor:'pointer',
				alignItems: 'center',
				fontSize:{
					xs:'0.8rem',
					sm:'1rem'
				},
			}} onClick={()=>{
				connect();
			}}>
			<Box
				component="img"
				sx={{
				  width: {
					  xs:'1rem',
					  sm:"1.3rem"
				  },
				  height: {
					  xs:'1rem',
					  sm:"1.3rem"
				  },
				  mr:1,
				}}
				alt=""
				src="../images/polygon.svg"
			  />
			{ currentAccountAddress==''?t('header.ConnectWallet'):`${currentAccountAddress.substring(0,2)}...${currentAccountAddress.substring(currentAccountAddress.length-3, currentAccountAddress.length)}` }
		</Box>
    );
};

