import * as React from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import {GameRulesPopup} from '../../components/GameRulesPopup'

import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery';

import { useNavigate , useSearchParams} from "react-router-dom";

import message from '../../utils/message';

import contractData from '../../contract/contract.json'

export default function Home() {
	const { t } = useTranslation();
	const matches = useMediaQuery('(min-width:600px)');
	
	const navigate = useNavigate();
	const [params] = useSearchParams();
	
	const [rulePopup,setRulePopup] = React.useState(false);
	const onRulesClose = ()=>{
		setRulePopup(false);
	}
	const openRulesPopup = ()=>{
		setRulePopup(true);
	}
	
	const startGame = ()=>{
		if(!window.ethereum){
			message.error({content: t('header.PleaseConnectPolygonWallet'),  duration: 4000});
			return;
		}
		
		if(window.ethereum.networkVersion!='80001'&&window.ethereum.networkVersion!='137'||!window.ethereum.selectedAddress){
			message.error({content: t('header.PleaseConnectPolygonWallet'),  duration: 4000});
			return;
		}
		navigate(`/main${params.getAll("ref")[0]?'?ref='+params.getAll("ref")[0]:''}`);
	}
	
	const viewCode = ()=>{
		window.location.href = 'https://github.com/0xlucky3/LuckyZoov1-Contract';
	}
	
	const animals = [
		{
			id:0,//dog
			width:{
				xs:'3rem',
				sm:"5rem",
				md:'7rem'
			},
			position:{
				bottom:'0%',
				left:{
					xs:0,
					sm:'-10%',
					md:'-20%'
				},
			}
		},
		{
			id:1,//dragon
			width:{
				xs:'4rem',
				sm:"6rem",
				md:'8rem'
			},
			position:{
				bottom:{
					xs:0,
					sm:'-15%',
					md:'-30%'
				},
				right:{
					xs:0,
					sm:'15%',
					md:'30%'
				},
			}
		},
		{
			id:2,//tiger
			width:{
				xs:'5rem',
				sm:"7rem",
				md:'9rem'
			},
			position:{
				bottom:{
				    xs:'-35%'
			    },
				left:{
					xs:'10%',
					sm:'-12%',
					md:'-25%'
				},
			}
		},
		{
			id:3,//horse
			width:{
				xs:'5rem',
				sm:"8rem",
				md:'10rem'
			},
			position:{
				bottom:'-30%',
				right:{
				    xs:0,
				    sm:'-15%',
				    md:'-30%'
				},
			}
		},
		{
			id:4,//monkey
			width:{
				xs:'3rem',
				sm:"5rem",
				md:'7rem'
			},
			position:{
				top:'70%',
				right:{
					xs:'10%',
					sm:'-10%'
				},
			}
		},
		{
			id:5,//snake
			width:{
				xs:'4rem',
				sm:"6rem",
				md:'8rem'
			},
			position:{
				top:'50%',
				left:{
					xs:'5%',
					sm:'15%',
					md:'30%'
				},
			}
		}
	]
	
	return (
		<div>
			<Box
				sx={{
				  width: "100vw",
				  height:"100vh",
				  background:"url(../images/scene/home.png) no-repeat center",
				  backgroundSize: "cover",
				  position:"relative",
				  overflowY:"auto",
				  userSelect:'none',
				  display:'flex',
				  justifyContent: 'center',
				  alignItems: 'center',
				}}
			>
				<Box sx={{
					position:"relative",
				}}>
					<Box
						component="img"
						sx={{
						  width: {
							  xs:'18rem',
							  sm:'25rem',
							  md:'30rem'
						  },
						  margin:'0 auto',
						  display:'block',
						  position:"relative",
						  zIndex:5,
						}}
						alt=""
						src={`../images/scene/game-name.png`}
					/>
					<Box sx={{
						width:{
							xs:'80%',
							sm:'70%',
							md:'60%'
						},
						margin:'1rem auto 0',
					}}>
						<Box
							component="img"
							sx={{
							  width:'100%',
							}}
							alt=""
							src={`../images/body/homeTitle.png`}
						/>
					</Box>
					<Box className="flex" sx={{
						width:{
							xs:'15rem',
							sm:'20rem',
							md:'25rem'
						},
						height:{
							xs:'2.5rem',
							sm:'3rem',
							md:'4rem'
						},
						borderRadius: '76px',
						margin:{
							xs:'2rem auto 2rem',
							sm:'2rem auto'
						},
						background: 'linear-gradient(180deg, rgba(255, 235, 59, 1) 0%, rgba(255, 141, 26, 1) 100%)',
						boxShadow: '0px 6px 20px 0px rgba(212, 48, 48, 1), 0px 6px 0px 0px rgba(212, 48, 48, 1)',
						position:"relative",
						zIndex:5,
						cursor:'pointer',
						color:'#fff'
					}} onClick={()=>{startGame()}}>
						<Box sx={{
							position:'absolute',
							borderRadius: '76px',
							width:'100%',
							height:'100%',
							background:"url(../images/scene/btn-bg.png) no-repeat center",
							backgroundSize: "cover",
						}}></Box>
						{t('game.play')}
					</Box>
					<Box className="flex" sx={{
						width:{
							xs:'15rem',
							sm:'20rem',
							md:'25rem'
						},
						height:{
							xs:'2.5rem',
							sm:'3rem',
							md:'4rem'
						},
						borderRadius: '76px',
						margin:'2rem auto',
						background: 'linear-gradient(180deg, rgba(244, 179, 255, 1) 0%, rgba(126, 71, 255, 1) 100%)',
						boxShadow: '0px 6px 20px 0px rgba(172, 51, 193, 1), 0px 6px 0px 0px rgba(126, 71, 255, 1)',
						position:"relative",
						zIndex:5,
						cursor:'pointer',
						color:'#fff'
					}} onClick={()=>{openRulesPopup()}}>
						<Box sx={{
							position:'absolute',
							borderRadius: '76px',
							width:'100%',
							height:'100%',
							background:"url(../images/scene/btn-bg.png) no-repeat center",
							backgroundSize: "cover",
						}}></Box>
						{t('game.RulesPlay')}
					</Box>
					<Box className="flex">
						<a href="https://twitter.com/0xluckyzoo" target="_Blank">
							<Box
								sx={{mr:2,cursor:'pointer',}}
								component="img"
								alt=""
								src={`../images/Twitter-home.svg`}
							/>
						</a>
						<a href="https://github.com/0xlucky3/LuckyZoov1-Contract" target="_Blank">
							<Box
								sx={{mr:2,cursor:'pointer',}}
								component="img"
								alt=""
								src={`../images/Github-home.svg`}
							/>
						</a>
						<a href={`https://polygonscan.com/address/${contractData.tokenAddress}`} target="_Blank">
							<Box
								sx={{cursor:'pointer',}}
								component="img"
								alt=""
								src={`../images/Polygon-home.svg`}
							/>
						</a>
					</Box>
					{
						animals.map((item,index)=>{
							return (
								<Box key={item.id} sx={{
									position:'absolute',
									top:item.position.top?item.position.top:'initial',
									bottom:item.position.bottom?item.position.bottom:'initial',
									left:item.position.left?item.position.left:'initial',
									right:item.position.right?item.position.right:'initial',
									cursor:'pointer',
								}}>
									<Box
										component="img"
										sx={{
											width: item.width,
											position:'relative',
											zIndex:1,
										}}
										alt=""
										src={`../images/animal/${item.id}.png`}
									/>
									<Box
										component="img"
										sx={{
											width: {
											    xs:'4rem',
											    sm:"6rem",
											    md:'8rem'
											},
											position:'absolute',
											left:index==4?'-0.7rem':index==3?'0.5rem':0,
											bottom:'-0.4rem',
										}}
										alt=""
										src={`../images/scene/animal-shadow.png`}
									/>
								</Box>
							)
						})
					}
				</Box>
			</Box>
			
			<GameRulesPopup isOpen={rulePopup} onClose={onRulesClose} />
		</div>
	);
}
