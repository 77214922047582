import * as React from 'react';
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

import {getAddress} from '../../utils/tool';

import copy from 'copy-to-clipboard';

import message from '../../utils/message';

export const RefPopup = ({isOpen,onClose}) => {
	const { t } = useTranslation();
	  
	const copyRef = ()=>{
		copy(`https://${window.location.host}?ref=${getAddress()}`);
		message.success({content: t("game.CopySucceeded"),  duration: 2000});
	}
	  
    return (
		<Dialog
				open={isOpen}
				onClose={()=>{
					onClose()
				}}
				fullWidth={true}
				maxWidth="sm"
				className="BetPopup"
			  >
			  <DialogContent sx={{
				  background:`rgba(165, 119, 72, 1)`,
				  border: '10px solid rgba(142, 57, 4, 0.5)',   
				  borderRadius: {
					  xs:'20px',
					  sm:'50px',
					  md:'76px'
				  },
				  color:'#fff'
			  }}>
					<Box sx={{textAlign:'center',wordWrap: 'break-word'}}>{`https://${window.location.host}?ref=${getAddress()}`}</Box>
					<Box className='flex' sx={{
						my:2
					}}>
						<Button
							onClick={()=>{
								copyRef();
							}}
							sx={{
								color:'#fff',
								borderRadius:5,
							}}  variant="contained">{t('game.copyInvitationLink')}</Button>
					</Box>
					<Box sx={{wordWrap: 'break-word',color:'rgba(255, 235, 59, 1)'}}>
						<Box className="ZooBlackFont">
							{t('rules.InvitationReward')}:
						</Box>
						<Box sx={{
							fontSize:'0.8rem'
						}}>
							{t('rules.InvitationRewardText1')}
						</Box>
						<Box sx={{
							fontSize:'0.8rem'
						}}>
							{t('rules.InvitationRewardText2')}
						</Box>
					</Box>
			   </DialogContent>
		</Dialog>
    );
};

