import * as React from 'react';
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import {getGameResultList} from '../../utils/contractUtils'

export const RecentLuckyNumberBox = React.forwardRef(({currentRound},ref) => {
	const { t } = useTranslation();
	
	const [historyLoading,setHistoryLoading] = React.useState(false);
	const [recentResults, setRecentResults] = React.useState([]);
	const [historyLotteryPopup,setHistoryLotteryPopup] = React.useState(false);
	
	React.useImperativeHandle(ref, () => ({
		getRecentResults: getRecentResults,
	}))
	
	const getRecentResults = (current,top=5) => {
		if(historyLoading){
			return;
		}
		setRecentResults([]);
		setHistoryLoading(true);
		return new Promise((resolve, reject) => {
			getGameResultList(top).then(res => {
				let arr = [];
				if(res){
					res.forEach((item,index)=>{
						let obj = [...item];
						obj.id = current - index
						arr.push(obj)
					})
				}
				setHistoryLotteryPopup(true);
				setRecentResults(arr);
				setHistoryLoading(false);
				resolve('getGameResultList');
			}).catch(err => {
				setHistoryLoading(false);
				reject('getGameResultList-error')
			})
		})
	}
	
	React.useEffect(() => {
		
	}, [])
	  
    return (
		<Box>
			<Dialog
					open={historyLotteryPopup}
					onClose={()=>{
						setHistoryLotteryPopup(false);
						setRecentResults([]);
					}}
					fullWidth={true}
					maxWidth="xs"
					className="BetPopup"
				  >
				  <DialogContent sx={{
						background:`url(../images/scene/chopse-animal.png) no-repeat center`,
						backgroundSize: "100% 100%",
						position:'relative',
				   }}>
						<Box sx={{
							padding:'2rem 0.5rem 0.5rem',
							display:'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
							<Box sx={{color:'#fff'}}>{t('game.RecordLatest30')}</Box>
							<Box
								component="img"
								sx={{
									height:'3rem',
									cursor:'pointer',
									position:'absolute',
									top:'0',
									right:'0'
								}} 
								onClick={()=>{setHistoryLotteryPopup(false);setRecentResults([]);}}
								alt=""
								src="../images/scene/close-x.png"/>
						</Box>
						<Box className='scrollArea' sx={{
							minHeight:'15rem',
							maxHeight:'15rem',
							padding:0.5,
							mb:4,
							overflowY:'auto',
						}}>
							{
								recentResults.map((item,index)=>{
									return(
										<Box className="flex" key={index} sx={{
											mb:2
										}}>
											<Box sx={{
												mr:1,
												minWidth:'5rem',
												textAlign:'center',
											}}>
												#{item.id}
											</Box>
											{
												['AnimalNo_1','AnimalNo_2','AnimalNo_3'].map((AnimalNo,AnimalNoIndex)=>{
													return (
														<Box key={AnimalNo} sx={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width:{xs:'2rem',sm:'2.5rem'},
															height:{xs:'2rem',sm:'2.5rem'},
															background:`url(../images/animal/-.png) no-repeat center`,
															backgroundSize: "contain",
															mr:1
														}}>
															<Box
																component="img"
																sx={{
																  width: '70%',
																}}
																alt=""
																src={`../images/animal/${item[AnimalNoIndex]}.png`}
															/>
														</Box>
													)
												})
											}
										</Box>
									)
								})
							}
						</Box>
				</DialogContent>
			</Dialog>
		</Box>
    );
});

