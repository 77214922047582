import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef((props,ref) =>{
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Message(props) {
    const { content, duration, type, boxClass } = {...props};
    const [open, setOpen] = React.useState(true);
    const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
		let box = document.getElementsByClassName(boxClass)[0];
		setTimeout(()=>{
			document.getElementsByTagName("body")[0].removeChild(box);
		},1000)
        setOpen(false);
    };
    return <Snackbar
        open={open}
        autoHideDuration={duration}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}>
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
				{content}
            </Alert>
        </Snackbar>
}

export default Message;
