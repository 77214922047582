import * as React from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { Shake } from 'reshake'
import copy from 'copy-to-clipboard';

import {NumberInput} from '../../components/NumberInput'
import {MyBetBox} from '../../components/MyBetBox'
import {RecentLuckyNumberBox} from '../../components/RecentLuckyNumberBox'
import {RefPopup} from '../../components/RefPopup'

import { useNavigate , useSearchParams} from "react-router-dom";

import message from '../../utils/message';

import i18n from 'i18next';
import BigNumber from "bignumber.js";

import {
		getCurrentRound,
		getGameStatus,
		getEstimateNextRoundTime,
		getGameResult,
		gameFeeConfig,
		getTInviteeCount,
		
		createTokenContract,
		receipt,
		
		queryAllUnPaidBonus,
		
		toWei,
		fromWei,
} from '../../utils/contractUtils'

import {format,formatSeconds,getAddress,shuffle,getLocalStorage} from '../../utils/tool'

import { useTranslation } from 'react-i18next'

import useMediaQuery from '@mui/material/useMediaQuery';

const Main = () => {
	const { t } = useTranslation();
	
	let timer = React.useRef('');
	let countDownTimer = React.useRef('');
	
	const MyBetRef = React.useRef(null);
	const RecentLuckyNumberRef = React.useRef(null);
	
	const matches = useMediaQuery('(min-width:600px)');
	
	//base
	const currentRound = React.useRef('-');
	const [nextRoundTime,setNextRoundTime] = React.useState(0);
	const [gameStatus,setGameStatus] = React.useState('-');
	const [waitinLottery,setWaitinLottery] = React.useState(false);
	const [lastTerm,setLastTerm] = React.useState(['','','']);
	const [SingleAmount,setSingleAmount] = React.useState(0.01);
	const [TInviteeCount,setTInviteeCount] = React.useState(0);
	const [AnimalConversion,setAnimalConversion] = React.useState(false);
	const [unaccalimed,setUnaccalimed] = React.useState('0');
	const [lotteryTime,setLotteryTime] = React.useState('-');
	const [summary,setSummary] = React.useState([]);
	const [tooltipOpen, setTooltipOpen] = React.useState(false);
	
	//loading
	const [initLoading,setInitLoading] = React.useState(true);
	const [betLoading,setBetLoading] = React.useState(false);
	const [extractLoading,setExtractLoading] = React.useState(false);
	
	//pop-up notification
	const [betPopup,setBetPopup] = React.useState(false);
	const [refPopup,setRefPopup] = React.useState(false);
	
	// limit
	const [BettingRestrictions,setBettingRestrictions] = React.useState(999);
	const [MultiplicationLimit,setMultiplicationLimit] = React.useState(999);
	
	const [params] = useSearchParams();
	
	const handleTooltipClose = () => {
		setTooltipOpen(false);
	};
	
	const handleTooltipOpen = () => {
		setTooltipOpen(true);
	};
	
	const onRefClose = ()=>{
		setRefPopup(false);
	}
	const openRefPopup = ()=>{
		setRefPopup(true);
	}
	
	const deleteSelection = (item,index) =>{
		let arr = [...summary];
		arr.splice(index,1);
		setSummary(arr);
	}
	
	const openHistoryLottery = ()=>{
		RecentLuckyNumberRef.current.getRecentResults(currentRound.current,30);
	}
	
	const openHistoryBet = ()=>{
		MyBetRef.current.getMyBet(0);
	}
	
	const openBetPopup = ()=>{
		if(waitinLottery){
			return;
		}
		setBetPopup(true)
	}
	
	const NumberInput_add = (data)=>{
		let arr = [...summary];
		arr.push(data);
		setSummary(arr);
	}
	
	const copyRef = ()=>{
		copy(`https://${window.location.host}?ref=${getAddress()}`);
		message.success({content: t("game.CopySucceeded"),  duration: 2000});
	}
	
	const getGameInfo = (type) => {
		return new Promise((resolve, reject) => {
			Promise.all([
				getGameStatus(),//Check status
				getCurrentRound(),//Check the current number of games
				getEstimateNextRoundTime(),
			]).then(res => {
				if(initLoading){
					setInitLoading(false);
				}
				if(res[0]){
					setGameStatus(res[0]);
				}
				if(res[1]){
					//A new round begins
					if(currentRound.current==''||res[1]!=currentRound.current){
						currentRound.current = res[1];
						setWaitinLottery(false);
						getGameResult(res[1]).then(res=>{
							if(res){
								setLastTerm(res);
							}
						})
						MyBetRef.current.getPastBets(res[1]);
						MyBetRef.current.getPreviousIsWin(res[1],type);
						getMyInfo();
					}
					
					if(res[0]==2){//Waiting for people to place bets requires polling until the time changes
						clearInterval(countDownTimer.value);
						countDownTimer.value = null;
						clearInterval(timer.value);
						timer.value = null;
						timer.value = setInterval(()=>{
							getGameInfo();
						},10000)
						setLotteryTime(t('game.Waiting'));
					}else{
						setNextRoundTime(res[2]);
					}
				}
				
				resolve('getGameInfo');
			}).catch(err => {
				reject('getGameInfo-error')
			})
		})
	}
	
	
	React.useEffect(() => {
		if(nextRoundTime){
			clearInterval(countDownTimer.value);
			countDownTimer.value = null;
			clearInterval(timer.value);
			timer.value = null;
			countDownTimer.value = setInterval(()=>{
				if(nextRoundTime>Date.parse(new Date())/1000){
					setLotteryTime(formatSeconds(nextRoundTime - Date.parse(new Date())/1000 ));
				}else{
					clearInterval(countDownTimer.value);
					countDownTimer.value = null;
					clearInterval(timer.value);
					timer.value = null;
					timer.value = setInterval(()=>{
						getGameInfo('timeEnd');
					},5000)
					setLotteryTime(t('game.WaitingLottery'));
					setWaitinLottery(true);//The game is drawing lottery -- you can't bet at this time
				}
			},1000)
		}
	}, [nextRoundTime])
	
	//extract
	const extract = ()=>{
		if(extractLoading){
			return;
		}
		setExtractLoading(true);
		createTokenContract().methods.withdrawAllBonus()
		.send({ from: window.ethereum.selectedAddress}, (err, res1) => {
			if (err) {
				setExtractLoading(false);
				message.error({content: t('game.ExtractFailed'),  duration: 2000});
			} else {
				receiptAction(res1,'extract');
			}
		});
	}
	
	//Obtain the number of areas to be invested
	const getBetQuantity = () =>{
		let betQuantity = 0;
		summary.forEach((item,index)=>{
			betQuantity += Number(item.multiple);
		})
		return new BigNumber(betQuantity).times(SingleAmount).toString();
	}
	
	//Confirm Betting
	const betting = ()=>{
		if(betLoading){
			return;
		}
		
		if(gameStatus=='1'){
			message.error({content: t('game.lotteryBeingOpened') ,  duration: 2000});
			return;
		}else if(gameStatus=='3'){
			message.error({content: t('game.GameStatusUnavailable') ,  duration: 2000});
			return;
		}
		
		if(summary.length==0){
			message.error({content: t('game.pleaseAdd'),  duration: 2000});
			return;
		}
		let batchBettingArr = [];
		summary.forEach((item,index)=>{
			batchBettingArr.push([item.AnimalNo_1=='N'?'10':item.AnimalNo_1,item.AnimalNo_2=='N'?'10':item.AnimalNo_2,item.AnimalNo_3=='N'?'10':item.AnimalNo_3,item.multiple,item.model]);
		})
		
		let refer = params.getAll("ref")[0]?params.getAll("ref")[0]:'0x0000000000000000000000000000000000000000';
		
		setBetLoading(true);
		createTokenContract().methods.batchBetting(batchBettingArr , refer).send({ from: window.ethereum.selectedAddress,value:toWei(getBetQuantity()+'')}, (err, res1) => {
			if (err) {
				setBetLoading(false);
				message.error({content: t('game.BetFailed'),  duration: 2000});
			} else {
				receiptAction(res1);
			}
		});
	}
	
	const clear = ()=>{
		if(timer.value){
			clearInterval(timer.value);
			timer.value = null;
		}
		if(countDownTimer.value){
			clearInterval(countDownTimer.value);
			countDownTimer.value = null;
		}
	}
	
	const getMyInfo = () => {
		return new Promise((resolve, reject) => {
			Promise.all([
				queryAllUnPaidBonus(getAddress()),
			]).then(res => {
				if(res[0]){
					setUnaccalimed(res[0]);
				}
				resolve('getMyInfo');
			}).catch(err => {
				reject('getMyInfo-error')
			})
		})
	}
	
	const getTInviteeCountInit = ()=>{
		return new Promise((resolve, reject) => {
			Promise.all([
				getTInviteeCount(),
			]).then(res => {
				if(res[0]){
					setTInviteeCount(res[0]);
				}
				resolve('getTInviteeCountInit');
			}).catch(err => {
				reject('getTInviteeCountInit-error')
			})
		})
	}
	
	const gameFee = () => {
		return new Promise((resolve, reject) => {
			Promise.all([
				gameFeeConfig(),
			]).then(res => {
				if(res[0]){
					setSingleAmount(fromWei(res[0].singleBetCost,18));
				}
				resolve('gameFee');
			}).catch(err => {
				reject('gameFee-error')
			})
		})
	}
	
	const receiptAction = (hash,type) => {
		receipt(hash).then((res) => {
			if(type=='extract'){
				setUnaccalimed(0);
				setExtractLoading(false);
				message.success({content: t('game.extractSucceeded'),  duration: 2000});
			}else{
				setBetLoading(false);
				setSummary([]);
				setBetPopup(false);
				message.success({content: t('game.successfulBet'),  duration: 2000});
				setTimeout(()=>{
					getMyInfo();
					MyBetRef.current.getPastBets(currentRound.current);
				},5000)
			}
		}).catch((e) => {
			setTimeout(() => {
				receiptAction(hash,type);
			}, 2000);
		});
	};
	
	React.useEffect(() => {
		if(lastTerm[0]!='-'){
			setAnimalConversion(true);
			setTimeout(()=>{
				setAnimalConversion(false);
			},2000)
		}
	}, [lastTerm])
  
	React.useEffect(() => {
		clear();
		setTimeout(()=>{
			if (window&&window.ethereum) {
				if(window.ethereum.networkVersion=='80001'||window.ethereum.networkVersion=='137'){
					if(getAddress()){
						getGameInfo();
						gameFee();
						getTInviteeCountInit();
					}
				}
			}
		},300)
	}, [])
	  
	  
  return (
	<Box sx={{
		position:"relative",
		userSelect:'none',
		background:'#75cd67',
		paddingBottom:{
			xs:'23rem',
			sm:'20rem'
		},
		overflowX: 'hidden',
		fontSize:{
			xs:'0.8rem',
			sm:'0.9rem',
			md:'1rem'
		},
	}}>
		<Box className="balloon" sx={{
			width: {
				xs:'8rem',
				sm:'10rem',
				md:'15rem'
			},
			position:"absolute",
			right:'5%',
			top:'0.5rem',
			zIndex:3
		}}>
			<Box
				component="img"
				sx={{
				  width:'100%'
				}}
				alt=""
				src={`../images/body/balloon.png`}
			/>
			<Box hidden sx={{
				position:"absolute",
				top:'35%',
				width:'100%',
				textAlign:'center',
				color:'rgba(255, 235, 59, 1)',
				textShadow:'0px 0px 5px #000',
			}}>
				<Box>{t('game.fans')}</Box>
				<Box>{TInviteeCount}</Box>
			</Box>
		</Box>
		<Box sx={{
			  width: "100vw",
			  height:"100vh",
			  background:"url(../images/scene/home.png) no-repeat center",
			  backgroundSize: "cover",
			  position:"absolute",
			  top:0,
			  zIndex:2
		}}>
			<Box
				component="img"
				sx={{
				  width:'100%',
				  height:'10rem',
				  position:"absolute",
				  bottom:'-5rem'
				}}
				alt=""
				src={`../images/body/lawn.png`}
			/>
		</Box>
		{
			//tree-left
		}
		<Box sx={{
			  width:{
				  xs:'20rem',
				  sm:'25rem'
			  },
			  position:"absolute",
			  left:'-5rem',
			  bottom:{
				  xs:'15%',
				  sm:'30%'
			  },
			  zIndex:{
				  xs:0,
				  sm:5
			  }
			}}>
			<Box
				component="img"
				sx={{
					width:'100%',
				}} 
				alt=""
				src={`../images/body/tree.png`}/>
			<Box
				component="img"
				sx={{
					height:{
						xs:'3rem',
						sm:'4rem',
						md:'6rem'
					},
					position:"absolute",
					bottom:{
						xs:'-1rem',
						sm:'-2rem',
						md:'-3rem'
					},
					left:'20%'
				}} 
				alt=""
				src={`../images/body/stone.png`}/>
			<Box
				component="img"
				sx={{
					height:{
						xs:'5rem',
						sm:'7rem',
						md:'9rem'
					},
					position:"absolute",
					bottom:'1rem',
					left:'40%'
				}} 
				alt=""
				src={`../images/body/TreelikeFlower.png`}/>
			<Box
				component="img"
				sx={{
					height:{
						xs:'1rem',
						sm:'2rem',
						md:'3rem'
					},
					position:"absolute",
					bottom:'1rem',
					left:'50%'
				}} 
				alt=""
				src={`../images/body/mushroom.png`}/>
		</Box>
		
		{
			//tree-right
		}
		<Box
			component="img"
			sx={{
			  width:'20rem',
			  position:"absolute",
			  right:'-8rem',
			  bottom:{
				  xs:'22%',
				  sm:'20%'
			  }
			}}
			alt=""
			src={`../images/body/tree-green.png`}
		/>
		
		<Container maxWidth="lg" sx={{position:"relative",zIndex:4,minHeight:'600px',paddingTop:'10rem'}}>
			<Box sx={{
				  width: {
					  xs:'20rem',
					  sm:"25rem",
					  md:'30rem'
				  },
				  height:"7rem",
				  margin:'1rem auto 0',
				  background:"url(../images/body/current-round.png) no-repeat center",
				  backgroundSize: "contain",
				  display:'flex',
				  justifyContent: 'center',
				  alignItems: 'center',
			}}>
				<Box sx={{
					pt:'0.6rem',
					textAlign:'center',
					color:'#fff',
					fontWeight:'700'
				}}>
					<Box>{t('game.CurrentRound')}</Box>
					<Box>#{currentRound.current!='-'?Number(currentRound.current)+1:'-'}</Box>
				</Box>
			</Box>
			<Box sx={{
				  width: {
					  xs:'20rem',
					  sm:"25rem",
					  md:'30rem'
				  },
				  height:"7rem",
				  margin:{
					  xs:'-2rem auto 6.5rem',
					  sm:'-1.8rem auto 15rem',
					  md:'-1.5rem auto 17em'
				  },
				  background:"url(../images/body/board.png) no-repeat center",
				  backgroundSize: "contain",
				  display:'flex',
				  justifyContent: 'center',
				  alignItems: 'center',
				  position:"relative",
				  zIndex:'-1'
			}}>
				<Box sx={{
					textAlign:'center',
					color:'rgba(133, 70, 15, 1)',
					paddingTop:'7%',
					fontWeight:'700'
				}}>
					<Box hidden={waitinLottery||gameStatus=='2'}>{t("game.EstimatedEndTime")}</Box>
					<Box className="flex">
						{lotteryTime}
						<Box className="flex" sx={{
							ml:1,
							display:waitinLottery?'flex':'none'
						}}>
							<CircularProgress size={20} color="inherit" />
						</Box>
						
					</Box>
				</Box>
			</Box>
			<Box sx={{
				  width: {
					  xs:'20rem',
					  sm:"25rem",
					  md:'30rem'
				  },
				  height:"8rem",
				  margin:'0 auto',
				  background:"url(../images/body/board-brown.png) no-repeat center",
				  backgroundSize: "contain",
				  display:'flex',
				  justifyContent: 'center',
				  alignItems: 'center',
				  position:"relative",
			}}>
				<Box sx={{
					textAlign:'center',
					color:'#fff',
					fontWeight:'700',
					paddingBottom:'3%',
				}}>
					<Box>#{currentRound.current} {t('game.LotteryResults')}</Box>
				</Box>
				<Box
					component="img"
					sx={{
					  width:'4rem',
					  position:"absolute",
					  left:0,
					  bottom:0,
					}}
					alt=""
					src={`../images/body/grass.png`}
				/>
				<Box
					component="img"
					sx={{
					  width:'4rem',
					  position:"absolute",
					  right:0,
					  bottom:0,
					}}
					alt=""
					src={`../images/body/grass.png`}
				/>
			</Box>
			<Box sx={{
				minHeight:{
					xs:'5rem',
					sm:'10rem',
					md:'15rem'
				},
				mb:4,
				mt:{
					xs:3,
					sm:5,
					md:5
				},
				position:"relative",
			}}>
				<Box className="lastMr0" sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					position:"relative",
					zIndex:1,
				}}>
					{lastTerm.map((item,index)=>{
						return (
							<Box key={index} sx={{
								position:"relative",
								display: 'flex',
								flexDirection:'column',
								justifyContent: 'center',
								alignItems: 'center',
								mr:{
									xs:4,
									sm:6,
									md:8
								},
							}}>
								<Shake
									className="flex"
									style={{flexDirection:'column',display:AnimalConversion?'none':'flex'}}
					                h={53}
					                v={5}
					                r={3}
					                dur={2000}
					                int={10}
					                max={100}
					                fixed={false}>
									<Box
										component="img"
										sx={{
											height:{
												xs:'4rem',
												sm:'7rem'
											},
											cursor: 'pointer',
										}} 
										alt=""
										src={`../images/animal/${item}.png`}/>
					            </Shake>
								<Box
									component="img"
									sx={{
										width:'100%',
										position:"absolute",
										left:'0%',
										bottom:'-10%',
										zIndex:5
									}} 
									alt=""
									src={`../images/body/thick-grass.png`}/>
								<Box
									hidden={!AnimalConversion}
									component="img"
									sx={{
										height:{
											xs:'4rem',
											sm:'6rem',
											md:'8rem'
										},
									}} 
									alt=""
									src={`../images/body/blast.gif`}/>
								<Box
									component="img"
									sx={{
										height:{
											xs:'4rem',
											sm:'6rem',
											md:'8rem'
										},
										marginTop:'-3rem !important',
										position:"relative",
										zIndex:"-1"
									}} 
									alt=""
									src={`../images/body/stump.png`}/>
							</Box>
						)
					})}
				</Box>
				<Box
					component="img"
					sx={{
						height:{
							xs:'4rem',
							sm:'6rem',
							md:'8rem'
						},
						position:"absolute",
						top:'1rem',
						right:'-3rem',
					}} 
					alt=""
					src={`../images/body/mushrooms.png`}/>
			</Box>
			
			<Box className='flex' sx={{
				margin:{
					xs:'1rem 0',
					sm:'5rem 0'
				},
				flexDirection:{
					xs:'column',
					sm:'row'
				}
			}}>
				<Box
					hidden={!matches||unaccalimed==0} 
					component="img"
					sx={{
						height:'5rem',
					}} 
					alt=""
					src={`../images/body/gold.png`}/>
				<Box sx={{
					  width: {
						  xs:'20rem',
						  sm:"40rem"
					  },
					  height:"10rem",
					  background:"url(../images/body/carpet.png) no-repeat center",
					  backgroundSize: "contain",
					  display:'flex',
					  justifyContent: 'center',
					  alignItems: 'center',
				}}>
					<Box className="ZooBlackFont" sx={{
						textAlign:'center',
						color:'rgba(130, 65, 12, 1)',
						fontWeight:'900'
					}}>
						<Box sx={{mb:0.5}}>{t('game.MyBonus')}</Box>
						<Box className="flex">
							<Box>{fromWei(unaccalimed,18)} Matic</Box>
							<ClickAwayListener onClickAway={handleTooltipClose}>
								<Tooltip title={
									<Box  sx={{textAlign:'left',color:'#fff'}}>
										<Box>{t(`rules.Bonus`)}</Box>
									</Box>
								} arrow 
									PopperProps={{
									  disablePortal: true,
									}}
									onClose={handleTooltipClose}
									open={tooltipOpen}
									disableFocusListener
									disableHoverListener
									disableTouchListener
									sx={{
										whiteSpace:'pre-wrap'
									}}
								>
									<Box
										component="img"
										sx={{
											width:'1rem',
											mx:1,
											cursor:'pointer'
										}} 
										alt=""
										src="../images/body/sigh.png"
										onClick={handleTooltipOpen}
									/>
								</Tooltip>
							</ClickAwayListener>
						</Box>
					</Box>
				</Box>
				
				<Box sx={{
					display:'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection:{
						xs:'row',
						sm:'column'
					}
				}}>
					<Box
						hidden={!matches||unaccalimed==0} 
						component="img"
						sx={{
							height:'5rem',
						}} 
						alt=""
						src={`../images/body/chest.png`}/>
					<Box hidden={unaccalimed==0} sx={{
						width:{
							xs:'5rem',
							sm:'10rem'
						},
						height:{
							xs:'2rem',
							sm:'3rem'
						},
						lineHeight:{
							xs:'2rem',
							sm:'3rem'
						},
						textAlign:'center',
						borderRadius: '76px',
						margin:'1rem auto 0',
						background: 'linear-gradient(180deg, rgba(172, 51, 193, 1) 0%, rgba(121, 72, 234, 1) 100%)',
						boxShadow: '0px 6px 0px 0px rgba(97, 47, 214, 1)',
						position:"relative",
						zIndex:5,
						color:'#fff',
						cursor:'pointer',
					}} onClick={()=>{extract()}}>
						<Box sx={{
							position:'absolute',
							borderRadius: '76px',
							width:'100%',
							height:'100%',
							background:"url(../images/scene/btn-bg.png) no-repeat center",
							backgroundSize: "cover",
						}}></Box>
						{t('game.Extract')}
					</Box>
				</Box>
			</Box>
			
			<Box>
				<MyBetBox ref={MyBetRef} currentRound={currentRound.current}/>
			</Box>
			
			<Box sx={{
				position:"relative",
			}}>
				<Box sx={{
					  width: {
						  xs:'15rem',
						  sm:"20rem",
						  md:'25rem'
					  },
					  height:{
						  xs:'12rem',
						  sm:"15rem"
					  },
					  background:"url(../images/body/link.png) no-repeat center",
					  backgroundSize: "contain",
					  display:'flex',
					  justifyContent: 'center',
					  alignItems: 'center',
					  position:"absolute",
					  left:'-5rem',
					  bottom:{
						  xs:'-9rem',
						  sm:'-6rem'
					  },
					  zIndex:'8'
				}}>
					<Box sx={{
						textAlign:'center',
						color:'#fff',
						paddingTop:{
							xs:'7%',
							md:'5%'
						},
						fontWeight:'700',
						cursor:'pointer',
					}} onClick={()=>{setRefPopup(true)}}>
						<Box>{t('game.InvitationLink')}</Box>
					</Box>
					<Box sx={{
							position:"absolute",
							left:{
								xs:'-1rem',
								sm:'-5rem'
							},
							bottom:{
								xs:'-4rem',
								sm:'-2rem'
							},
						  width: {
							  xs:'20rem',
							  sm:"25rem",
							  md:'30rem'
						  },
						  height:"6rem",
						  background:"url(../images/body/grass-ref.png) no-repeat center",
						  backgroundSize: "contain",
						  display:'flex',
						  justifyContent: 'center',
						  alignItems: 'center',
					}}>
						<Box sx={{
							pt:'2.2rem',
							textAlign:'center',
							color:'rgba(255, 235, 59, 1)',
							fontWeight:'700',
							fontSize:{
								xs:'0.8rem',
								sm:'0.9rem'
							}
						}}>
							<Box>{t('game.fans')}：{TInviteeCount}</Box>
							<Box></Box>
						</Box>
					</Box>
				</Box>
				
				
				
				<Box sx={{
					  width: {
						  xs:'15rem',
						  sm:"20rem",
						  md:'25rem'
					  },
					  height:{
						  xs:'12rem',
						  sm:"15rem"
					  },
					  background:"url(../images/body/history.png) no-repeat center",
					  backgroundSize: "contain",
					  display:'flex',
					  justifyContent: 'center',
					  position:"absolute",
					  right:'-5rem',
					  bottom:{
						  xs:'-12rem',
						  sm:'-8rem'
					  },
					  zIndex:'8'
				}}>
					<Box sx={{
						textAlign:'center',
						color:'rgba(133, 70, 15, 1)',
						paddingTop:{
							xs:'17%',
							md:'14%'
						},
						fontWeight:'700',
						cursor:'pointer',
					}}>
						<Box sx={{
							mb:{
								xs:'1.6rem',
								sm:'2.1rem'
							},
							 transform:'rotate(-5deg)'
						}} onClick={()=>{openHistoryLottery()}}>{t('game.RecentResults')}</Box>
						<Box sx={{
							mr:'1rem',
							pt:{
								xs:0.6,
								sm:0
							},
						}} onClick={()=>{openHistoryBet()}}>{t('game.MyHistoryBet')}</Box>
					</Box>
					<Box
						component="img"
						sx={{
							height:{
								xs:'2rem',
								sm:'3rem'
							},
							position:"absolute",
							bottom:'0',
						}} 
						alt=""
						src={`../images/body/mushroom.png`}/>
				</Box>
				
			</Box>
			
		</Container>
		
		<Box className="flex" sx={{
			position:'fixed',
			bottom:'5rem',
			left:'50%',
			zIndex:999,
			transform:'translateX(-50%)',
			width:{
				xs:'12rem',
				sm:'18rem',
				md:'23rem'
			},
			height:{
				xs:'2.5rem',
				sm:'3rem',
				md:'3.5rem'
			},
			borderRadius: '76px',
			background: waitinLottery?'#a5a5a5':'linear-gradient(180deg, rgba(255, 235, 59, 1) 0%, rgba(255, 141, 26, 1) 100%)',
			boxShadow: waitinLottery?'0px 6px 20px 0px #ddd, 0px 6px 0px 0px #aaa':'0px 6px 20px 0px rgba(212, 48, 48, 1), 0px 6px 0px 0px rgba(212, 48, 48, 1)',
			color:'#fff',
			cursor:waitinLottery?'no-drop':'pointer',
		}} onClick={()=>{openBetPopup();}}>
			<Box sx={{
				position:'absolute',
				borderRadius: '76px',
				width:'100%',
				height:'100%',
				background:"url(../images/scene/btn-bg.png) no-repeat center",
				backgroundSize: "cover",
			}}></Box>
			{waitinLottery?t('game.WaitingLottery'):t('game.BetNow')}
		</Box>
		
		
		<Dialog
				open={betPopup}
				onClose={()=>{
					setBetPopup(false)
				}}
				fullWidth={true}
				maxWidth="sm"
				className="BetPopup"
			  >
			  <DialogContent className="scrollArea" sx={{
				  background:`rgba(165, 119, 72, 1)`,
				  border: {
					  xs:'5px solid rgba(142, 57, 4, 0.5)',
					  sm:'8px solid rgba(142, 57, 4, 0.5)',
					  md:'10px solid rgba(142, 57, 4, 0.5)'
				  },   
				  borderRadius: {
					  xs:'20px',
					  sm:'50px',
					  md:'76px'
				  },
			  }}>
					<Box sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						mb:1,
						textAlign:'center',
						color:'rgba(77, 25, 9, 1)',
						fontSize:{
							xs:'1rem',
							sm:'1.5rem',
							md:'2rem'
						}
					}}>
						<Box>
							{t('game.EnterThreeDigits')}
						</Box>
					</Box>
					
					<Box sx={{
						py:0.2
					}}>
						{
							['1','2','3','4'].map(item=>{
								return (
									<NumberInput key={item} model={item} MultiplicationLimit={MultiplicationLimit} add={NumberInput_add}/>
								)
							})
						}
						
						
						<Box className="scrollArea" sx={{
							height:'8rem',
							overflowY:'auto',
							color:'rgba(23, 23, 23, 1)',
							display: 'grid',
							gap: 0.5,
							gridTemplateColumns: {
								xs:'repeat(2, 1fr)',
								sm:'repeat(2, 1fr)'
							},
							fontWeight:400,
							p:1,
							fontSize:{
								xs:'0.8rem',
								sm:'1rem'
							},
							borderRadius:'25px',
							background: 'rgba(148, 103, 58, 1)',
							border:'5px solid rgba(171, 126, 82, 1)',
							my:1
						}}>
							{
								summary.map((item,index)=>{
									return (
										<Box key={index} sx={{
											textAlign:'center',
											position:"relative",
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											fontSize:{
												xs:'0.6rem',
												sm:'0.8rem',
												md:'1rem'
											}
										}}>
											{
												['AnimalNo_1','AnimalNo_2','AnimalNo_3'].map((AnimalNo,AnimalNoIndex)=>{
													return (
														<Box key={AnimalNo} sx={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width:{xs:'1.5rem',sm:'2rem'},
															height:{xs:'1.5rem',sm:'2rem'},
															background:`url(../images/animal/-.png) no-repeat center`,
															backgroundSize: "contain",
															mr:{
																xs:0.2,
																sm:0.5
															}
														}}>
															{item[AnimalNo]=='N'?('N'):(
																<Box
																	component="img"
																	sx={{
																	  width: '70%',
																	}}
																	alt=""
																	src={`../images/animal/${item[AnimalNo]}.png`}
																/>
															)}
														</Box>
													)
												})
											}
											 {`(M${item.model_text}) × ${item.multiple}`}
											<Box
												component="img"
												sx={{
													width:'1rem',
													height:'1rem',
													cursor:'pointer',
													ml:0.2
												}} 
												onClick={()=>{deleteSelection(item,index)}}
												alt=""
												src="../images/scene/close.png"/>
										</Box>
									)
								})
							}
						</Box>
					</Box>
					<Box>
						<Box sx={{
								textAlign:'center',
								fontSize:{
									xs:'0.8rem',
									sm:'0.9rem',
									xl:'1rem'
								}
							}} >{t('game.JointPayment')}：{getBetQuantity()} Matic </Box>
						
						<Box className="flex" sx={{
							width:{
								xs:'10rem',
								sm:'12rem',
								md:'14rem'
							},
							height:{
								xs:'2.2rem',
								sm:'2.5rem',
								md:'2.8rem'
							},
							textAlign:'center',
							borderRadius: '76px',
							margin:'0.5rem auto',
							background: 'linear-gradient(180deg, rgba(142, 57, 4, 1) 0%, rgba(255, 141, 26, 1) 100%)',
							boxShadow: '0px 6px 20px 0px rgba(142, 57, 4, 1), 0px 6px 0px 0px rgba(255, 141, 26, 1)',
							color:"#fff",
							cursor:betLoading?'no-drop':'pointer',
							position:"relative",
						}} onClick={()=>{betting()}}>
							<Box sx={{
								position:'absolute',
								borderRadius: '76px',
								width:'100%',
								height:'100%',
								background:"url(../images/scene/btn-bg.png) no-repeat center",
								backgroundSize: "cover",
							}}></Box>
							{betLoading?t('game.Betting')+t('game.loading'):t('game.ConfirmBet')}
							<Box className="flex" sx={{
								ml:1,
								display:betLoading?'flex':'none'
							}}>
								<CircularProgress size={20} color="inherit" />
							</Box>
						</Box>
						
						
					</Box>
			   </DialogContent>
		</Dialog>
		
		<RefPopup isOpen={refPopup} onClose={onRefClose} />
		
		<RecentLuckyNumberBox ref={RecentLuckyNumberRef} currentRound={currentRound.current}/>
		
	</Box>
  );
};

export default Main;
