import * as React from 'react';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Router from './router';
import Header from './components/Header'
import './language/i18n';
import './styles/globals.css'

import {BrowserRouter} from 'react-router-dom';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<BrowserRouter>
			<Header />
			<Router />
		</BrowserRouter>
	</ThemeProvider>,
);
