import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {getLocalStorage} from '../utils/tool';

import zh_tw from './modules/zh_tw.json';
import en_us from './modules/en_us.json';

const resources = {
	zh_tw: {
	    translation: zh_tw,
	},
    en_us: {
        translation: en_us,
    },
};

let lng = 'en_us';
if(typeof window != "undefined") {
	lng = getLocalStorage('language','en_us')
}


i18n.use(initReactI18next).init({
    resources,
    lng: lng,
	debug:false,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
