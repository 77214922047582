import * as React from 'react';
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


import message from '../../utils/message';

export const NumberInput = React.forwardRef(({model='1',MultiplicationLimit=999,add},ref) => {
	const { t } = useTranslation();
	
	const [AnimalNo_1,setAnimalNo_1] = React.useState('?');
	const [AnimalNo_2,setAnimalNo_2] = React.useState('?');
	const [AnimalNo_3,setAnimalNo_3] = React.useState('?');
	const [multiple,setMultiple] = React.useState('1');
	
	const [chooseNumber,setChooseNumber] = React.useState(false);
	const [tooltipOpen, setTooltipOpen] = React.useState(false);
	
	const [clickIndex,setClickIndex] = React.useState(0);
	
	const addClick = ()=>{
		let isSure = false;
		if(model == '1'||model == '2'){
			if(AnimalNo_1!='?'&&AnimalNo_2!='?'&&AnimalNo_3!='?'){
				add({
					AnimalNo_1:AnimalNo_1,
					AnimalNo_2:AnimalNo_2,
					AnimalNo_3:AnimalNo_3,
					multiple:checkMultiple(multiple),
					model:model=='1'?0:1,
					supplement:supplement(AnimalNo_1,AnimalNo_2,AnimalNo_3),
					model_text:model,
				})
				isSure = true;
			}else{
				message.error({content: t(`rules.model${model}`),  duration: 2000});
			}
		}else if(model == '3'){
			if(AnimalNo_1!='?'&&AnimalNo_2!='?'){
				add({
					AnimalNo_1:AnimalNo_1,
					AnimalNo_2:AnimalNo_2,
					AnimalNo_3:AnimalNo_3,
					multiple:checkMultiple(multiple),
					model:2,
					supplement:supplement(AnimalNo_1,AnimalNo_2,AnimalNo_3),
					model_text:'3',
				})
				isSure = true;
			}else{
				message.error({content: t('rules.model3'),  duration: 2000});
			}
		}else if(model == '4'){
			if(AnimalNo_1!='?'){
				add({
					AnimalNo_1:AnimalNo_1,
					AnimalNo_2:AnimalNo_2,
					AnimalNo_3:AnimalNo_3,
					multiple:checkMultiple(multiple),
					model:3,
					supplement:supplement(AnimalNo_1,AnimalNo_2,AnimalNo_3),
					model_text:'4',
				})
				isSure = true;
			}else{
				message.error({content: t('rules.model4'),  duration: 2000});
			}
		}
		if(isSure){
			setAnimalNo_1('?');
			setAnimalNo_2('?');
			setAnimalNo_3('?');
		}
	}
	
	const checkMultiple = (mul)=>{
		return /^[+]{0,1}(\d+)$/.test(mul)?parseInt(mul)>0?parseInt(mul):1:1;
	}
	
	const supplement = (AnimalNo_1,AnimalNo_2,AnimalNo_3)=>{
		AnimalNo_1 = AnimalNo_1 == 'N' ? 0 : AnimalNo_1;
		AnimalNo_2 = AnimalNo_2 == 'N' ? 0 : AnimalNo_2;
		AnimalNo_3 = AnimalNo_3 == 'N' ? 0 : AnimalNo_3;
		return AnimalNo_1+AnimalNo_2+AnimalNo_3;
	}
	
	const handleTooltipClose = () => {
		setTooltipOpen(false);
	};
	
	const handleTooltipOpen = () => {
		setTooltipOpen(true);
	};
	
	const openChoosePopup = (index,num)=>{
		if(num == 'N'){
			return;
		}
		setChooseNumber(true);
		setClickIndex(index);
	}
	
	const Multiple_Operation = (type)=>{
		if(!multiple){
			setMultiple('1');
		}
		if(type == 'add'){
			if(Number(multiple)>=MultiplicationLimit){
				message.error({content: t('game.Maximum'),  duration: 2000});
				return;
			}
			setMultiple((Number(multiple)+1).toString());
		}else if(type == 'reduce'){
			if(Number(multiple)>1){
				setMultiple((Number(multiple)-1).toString());
			}
		}
	}
	
	const Multiple_Input = (e) => {
		let value = e.target.value.replace(/[^0-9]/, '');
		if(value.length>=4){
			value = value.slice(0,maxLength-1);
		}
		setMultiple(value);
	}
	
	const clickNumber = (animalName)=>{
		if([AnimalNo_1,AnimalNo_2,AnimalNo_3].indexOf(animalName)!='-1'){
			return;		
		}
		
		eval(`setAnimalNo_${clickIndex}`+"(animalName)");
		let animalArr = [1,2,3];
		if(model == '3'){

			let number = 0;
			let animalIndexArr = [];
			animalArr.forEach((item,index)=>{
				eval(`number = AnimalNo_${item}`);
				if(item!=clickIndex && number == '?'){
					animalIndexArr.push(index);
				}
			})
			
			if(animalIndexArr.length==1){
				eval(`setAnimalNo_${animalArr[animalIndexArr[0]]}`+"('N')");
			}
		}else if(model == '4'){
			animalArr.forEach((item)=>{
				if(item!=clickIndex){
					eval(`setAnimalNo_${item}`+"('N')")
				}
			})
		}else{
			
		}
		
		setChooseNumber(false);
	}
	
	const Reselect = (e,delIndex)=>{
		e.stopPropagation();
		eval(`setAnimalNo_${delIndex}`+"('?')");
		let animalArr = [1,2,3];
		if(model == '3'){
			let number = 0;
			let animalIndexArr = []; 
			animalArr.forEach((item,index)=>{
				eval(`number = AnimalNo_${item}`);
				if(item!=delIndex && number == 'N'){
					animalIndexArr.push(index);
				}
			})
			if(animalIndexArr.length==1){
				eval(`setAnimalNo_${animalArr[animalIndexArr[0]]}`+"('?')");
			}
		}else if(model == '4'){
			animalArr.forEach((item)=>{
				if(item!=delIndex){
					eval(`setAnimalNo_${item}`+"('?')")
				}
			})
		}
	}
	
	React.useEffect(() => {
		
	}, [])
	  
    return (
		<Box>
			<Box 
				className="flex"
				sx={{
					color:'rgba(77, 25, 9, 1)',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					fontSize:{
						xs:'0.8rem',
						sm:'0.9rem',
						md:'1rem'
					},
				}}
			>
				{t(`rules.M${model}`)}
				<ClickAwayListener onClickAway={handleTooltipClose}>
					<Tooltip title={
						<Box  sx={{textAlign:'left',color:'#fff'}}>
							<Box>{t(`rules.rule${model}`)}</Box>
						</Box>
					} arrow 
						PopperProps={{
						  disablePortal: true,
						}}
						onClose={handleTooltipClose}
						open={tooltipOpen}
						disableFocusListener
						disableHoverListener
						disableTouchListener
						sx={{
							whiteSpace:'pre-wrap'
						}}
					>
						<Box
							component="img"
							sx={{
								width:'1rem',
								mx:1,
								cursor:'pointer'
							}} 
							alt=""
							src="../images/scene/question.png"
							onClick={handleTooltipOpen}
						/>
					</Tooltip>
				</ClickAwayListener>
				<Box sx={{
					background:`url(../images/body/multiple.png) no-repeat center`,
					backgroundSize: "contain",
					minWidth:'3rem',
					height:'2rem',
					lineHeight:'2rem',
					fontSize:'0.8rem',
					textAlign:'center',
					pl:2,
					pr:1
				}}>
					{t(`rules.${model}X`)}
				</Box>
			</Box>
			<Box sx={{
				mb:{xs:1,sm:2},
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
				<Box sx={{
					display: 'flex',	
					alignItems: 'center',
					color:'rgba(77, 25, 9, 1)',
					fontSize:{xs:'1.2rem',sm:'1.3rem',md:'1.5rem'}
				}}>
					<Box sx={{mr:{xs:1,sm:2}}}>
						<Box sx={{
							position:'relative',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width:{xs:'2.5rem',sm:'2.8rem',md:'3rem',xl:'3.5rem'},
							height:{xs:'2.5rem',sm:'2.8rem',md:'3rem',xl:'3.5rem'},
							background:`url(../images/animal/-.png) no-repeat center`,
							backgroundSize: "contain",
							cursor:`${AnimalNo_1!='N'?'pointer':'no-drop'}`
						}} onClick={()=>{openChoosePopup(1,AnimalNo_1)}}>
							{AnimalNo_1=='?'||AnimalNo_1=='N'?AnimalNo_1:(
								<Box
									component="img"
									sx={{
									  width: '70%',
									}}
									alt=""
									src={`../images/animal/${AnimalNo_1}.png`}
								/>
							)}
							{
								(model == '3' || model == '4') && (AnimalNo_1!='?'&&AnimalNo_1!='N')?(
									<Box
										component="img"
										sx={{
											height:'2rem',
											cursor:'pointer',
											position:'absolute',
											right:'-1rem',
											top:'-1rem'
										}} 
										onClick={(e)=>{Reselect(e,1)}}
										alt=""
										src="../images/scene/close-x.png"/>
								):('')
							}
						</Box>
					</Box>
					<Box sx={{mr:{xs:1,sm:2}}}>
						<Box sx={{
							position:'relative',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width:{xs:'2.5rem',sm:'2.8rem',md:'3rem',xl:'3.5rem'},
							height:{xs:'2.5rem',sm:'2.8rem',md:'3rem',xl:'3.5rem'},
							background:`url(../images/animal/-.png) no-repeat center`,
							backgroundSize: "contain",
							cursor:`${AnimalNo_2!='N'?'pointer':'no-drop'}`,
							textAlign:'center',
							lineHeight:{xs:'2.8rem',sm:'3.5rem'}
						}} onClick={()=>{openChoosePopup(2,AnimalNo_2)}}>
							{AnimalNo_2=='?'||AnimalNo_2=='N'?AnimalNo_2:(
								<Box
									component="img"
									sx={{
									  width: '70%',
									}}
									alt=""
									src={`../images/animal/${AnimalNo_2}.png`}
								/>
							)}
							{
								(model == '3' || model == '4') && (AnimalNo_2!='?'&&AnimalNo_2!='N')?(
									<Box
										component="img"
										sx={{
											height:'2rem',
											cursor:'pointer',
											position:'absolute',
											right:'-1rem',
											top:'-1rem'
										}} 
										onClick={(e)=>{Reselect(e,2)}}
										alt=""
										src="../images/scene/close-x.png"/>
								):('')
							}
						</Box>
					</Box>
					<Box sx={{mr:{xs:1,sm:2}}}>
						<Box sx={{
							position:'relative',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width:{xs:'2.5rem',sm:'2.8rem',md:'3rem',xl:'3.5rem'},
							height:{xs:'2.5rem',sm:'2.8rem',md:'3rem',xl:'3.5rem'},
							background:`url(../images/animal/-.png) no-repeat center`,
							backgroundSize: "contain",
							cursor:`${AnimalNo_3!='N'?'pointer':'no-drop'}`,
							textAlign:'center',
							lineHeight:{xs:'2.8rem',sm:'3.5rem'}
						}} onClick={()=>{openChoosePopup(3,AnimalNo_3)}}>
							{AnimalNo_3=='?'||AnimalNo_3=='N'?AnimalNo_3:(
								<Box
									component="img"
									sx={{
									  width: '70%',
									}}
									alt=""
									src={`../images/animal/${AnimalNo_3}.png`}
								/>
							)}
							{
								(model == '3' || model == '4') && (AnimalNo_3!='?'&&AnimalNo_3!='N')?(
									<Box
										component="img"
										sx={{
											height:'2rem',
											cursor:'pointer',
											position:'absolute',
											right:'-1rem',
											top:'-1rem'
										}} 
										onClick={(e)=>{Reselect(e,3)}}
										alt=""
										src="../images/scene/close-x.png"/>
								):('')
							}
						</Box>
					</Box>
					<Box sx={{
						mr:1,
					}}>
						×
					</Box>
					<Box
						component="img"
						sx={{
							width:{xs:'0.8rem',sm:'1.2rem',md:'1.4rem'},
							mr:1,
								cursor:'pointer',
						}} 
						src="../images/scene/reduce.png"
						title={t('game.ReductionMultiple')}
					onClick={()=>{Multiple_Operation('reduce')}}/>
					
					<Box sx={{
						mr:1,
						width:{xs:'2.3rem',sm:'2.5rem',md:'2.8rem',xl:'3.2rem'},
						height:{xs:'2.3rem',sm:'2.5rem',md:'2.8rem',xl:'3.2rem'},
					}}>
						<Box 
							sx={{
								background:'rgba(148, 103, 58, 1)',
								color:'rgba(77, 25, 9, 1)',
								borderRadius: '10px',
								fontSize:{
									xs:'0.8rem',
									sm:'1rem',
									md:'1.2rem',
								},
								outline:'none',
								border:'none',
								width:'100%',
								height:'100%',
								display:'block',
								textAlign:'center',
							}}
							component="input" 
							type="number" 
							onChange={(e)=>{Multiple_Input(e)}} 
							value={multiple}/>
					</Box>
					
					<Box component="img"
						sx={{
							width:{xs:'0.8rem',sm:'1.2rem',md:'1.4rem'},
							mr:1,
								cursor:'pointer',
						}} 
						src="../images/scene/add.png"
						title={t('game.Multiplier')}
						 onClick={()=>{Multiple_Operation('add')}}/>
					
					<Button
						onClick={()=>{
							addClick();
						}}
						sx={{
							color:'#fff',
							px:{
								xs:0,
								sm:2
							},
							borderRadius:5,
						}}  size="small" variant="contained">+ {t("game.Add")}</Button>
					
				</Box>
			</Box>
			
			
			<Dialog
					open={chooseNumber}
					onClose={()=>{
						setChooseNumber(false)
					}}
					fullWidth={true}
					maxWidth="xs"
					className="BetPopup"
				  >
				  <DialogContent sx={{
						background:`url(../images/scene/chopse-animal.png) no-repeat center`,
						backgroundSize: "100% 100%",
						position:'relative',
				   }}>
						<Box sx={{
							padding:'2rem',
							paddingBottom:'0',
							display:'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
						}}>
							<Box
								component="img"
								sx={{
									height:'2rem',
									cursor:'pointer',
								}} 
								onClick={()=>{setChooseNumber(false)}}
								alt=""
								src="../images/scene/close-x.png"/>
						</Box>
						<Box sx={{
						      mb:'2rem',
							  display: 'grid',
							  gap: 0,
							  gridTemplateColumns:'repeat(3, 1fr)',
							  px:{xs:0,sm:2}
						}}>
							{
								['0','1','2','3','4','5'].map((item,index)=>{
									return (
										<Box key={index} sx={{userSelect:'none'}}>
											<Box sx={{
												width:{
													xs:'3.5rem',
													sm:'4rem',
													md:'5rem',
												},
												height:{
													xs:'3.5rem',
													sm:'4rem',
													md:'5rem',
												},
												margin:'0 auto',
												background:`url(../images/animal/${item}.png) no-repeat center`,
												backgroundSize: "contain",
												mb:2,
												cursor:[AnimalNo_1,AnimalNo_2,AnimalNo_3].indexOf(item)=='-1'?'pointer':'no-drop',
												opacity:[AnimalNo_1,AnimalNo_2,AnimalNo_3].indexOf(item)=='-1'?'1':'0.3',
											}} onClick={()=>{clickNumber(item)}}></Box>
										</Box>
									)
								})
							}
						</Box>
				</DialogContent>
			</Dialog>
		</Box>
    );
});

