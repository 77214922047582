import {
	Routes,
	Route
} from "react-router-dom";
import Home from '../pages/home';
import Main from '../pages/main';
import NotConnected from '../pages/notConnected';

function Router() {
	return ( 
		<Routes> 
			<Route path="/" element={<Home/>}></Route>  
			<Route path="/home" element={<Home/>}></Route>        
			<Route path="/main" element={<Main/>}></Route>
			<Route path="/notConnected" element={<NotConnected/>}></Route>  
		</Routes> 
	);   
}     

export default Router;
