import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next'

const NotConnected = () => {
	const { t } = useTranslation();
	
	React.useEffect(() => {
		
	}, [])
	  
    return (
		<Box className='flex' sx={{color:'#fff',background:"linear-gradient(135deg, #f2caff 0%, #ff7a75 100%)",width: "100vw",
			  height:"100vh"}}>
			<Box sx={{fontSize:{
				sm:'24px',
				lg:'32px'
			}}}>
				{t("header.PleaseConnectPolygonWallet")}
			</Box>
		</Box>
    );
};

export default NotConnected;
