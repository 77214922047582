export function add0 (m){
	return m<10?'0'+m:m 
}

export function format (cuo){
	var time = new Date(parseInt(cuo));
	var y = time.getFullYear();
	var m = time.getMonth()+1;
	var d = time.getDate();
	var h = time.getHours();
	var mm = time.getMinutes();
	var s = time.getSeconds();
	return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);
}

export function formatSeconds(value) {
    var second = parseInt(value);
    var minute = 0;
    var hour = 0;
    if (second >= 60) {
        minute = parseInt(second / 60);
        second = parseInt(second % 60);
        if (minute >= 60) {
            hour = parseInt(minute / 60);
            minute = parseInt(minute % 60);
        }
    }
    return `${add0(hour)}:${add0(minute)}:${add0(second)}`;
}

export function getAddress () {
	if(window&&window.ethereum){
		if (window?.ethereum.selectedAddress !== undefined || window?.ethereum.address !== undefined) {
			return window.ethereum.selectedAddress || window.ethereum.address
		}
	}
	return false
}

export const getLocalStorage = (key, def) => {
    const data = window.localStorage.getItem(key)
    return data ? data : def
}

export const loadImage = (url) => {     
	let img = new Image();  
	img.src = url; 
}

export function shuffle(arr) {
    let i = arr.length;
    while (i) {
        let j = Math.floor(Math.random() * i--);
        [arr[j], arr[i]] = [arr[i], arr[j]];
    }
    return arr;
}
