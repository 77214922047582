import * as React from 'react';
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export const GameRulesPopup = ({isOpen,onClose}) => {
	const { t } = useTranslation();
	  
    return (
		<Dialog
		    open={isOpen}
			onClose={()=>{
				onClose()
			}}
			fullWidth={true}
			maxWidth="xs"
			className="rulesPopup"
		>
		    <DialogContent 
				sx={{
					
				}}
				className="scrollArea"
			>
				<Box
					sx={{
						fontSize:"12px",
						minWidth:'200px',
						color:'#fff'
					}}>
					<Box 
						sx={{
							mb:1,
						}}
					>
						{t('rules.IntroduceText1')}
					</Box>
					<Box
						sx={{
							mb:1,
						}}
					>
						{t('rules.IntroduceText2')}
					</Box>
					<Box className="ZooBlackFont">
						{t('rules.IntroductionPlayMode')}:
					</Box>
					<Box>
						{t('rules.M1_title')}:
					</Box>
					<Box>
						{t('rules.rule1')}
					</Box>
					<Box>
						{t('rules.M2_title')}:
					</Box>
					<Box>
						{t('rules.rule2')}
					</Box>
					<Box>
						{t('rules.M3_title')}:
					</Box>
					<Box>
						{t('rules.rule3')}
					</Box>
					<Box>
						{t('rules.M4_title')}:
					</Box>
					<Box
						sx={{
							mb:1,
						}}
					>
						{t('rules.rule4')}
					</Box>
					<Box className="ZooBlackFont">
						{t('rules.BettingCost')}:
					</Box>
					<Box
						sx={{
							mb:1,
						}}
					>
						{t('rules.BettingCostText')}
					</Box>
					<Box className="ZooBlackFont">
						{t('rules.AboutPrizePoolFund')}:
					</Box>
					<Box
						sx={{
							mb:1,
						}}
					>
						{t('rules.AboutPrizePoolFundText')}
					</Box>
				</Box>
				<Box
					onClick={()=>{
						onClose()
					}}
					component="img"
					sx={{
					  width: "1.5rem",
					  margin:"10px auto",
					  display:"block",
					  cursor:'pointer'
					}}
					alt=""
					src="../images/close.png"
				/>
			</DialogContent>
		</Dialog>
    );
};

