import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next'
import ConnectWallet from '../ConnectWallet'
import useMediaQuery from '@mui/material/useMediaQuery';

import ReactAudioPlayer from 'react-audio-player';

import {getLocalStorage} from '../../utils/tool';

import { useNavigate , useSearchParams} from "react-router-dom";

import {RefPopup} from '../../components/RefPopup'

export default function Header() {
	const { t } = useTranslation();
	
	const [pathname,setPathname] = React.useState('/');
	const navigate = useNavigate();
	
	const matches = useMediaQuery('(min-width:600px)');
	
	const audioRef = React.useRef(null);
	const [refPopup,setRefPopup] = React.useState(false);
	const [musicPlay,setMusicPlay] = React.useState(false);
	const [musicAutoPlay,setMusicAutoPlay] = React.useState(1);
	
	const [open, setOpen] = React.useState(false);
	const language = [{
		name:'English',
		title:'en_us'
	},
	// {
	// 	name:'日本語',
	// 	title:'1'
	// },{
	// 	name:'한국어',
	// 	title:'2'
	// },{
	// 	name:'Tiếng Việt',
	// 	title:'3'
	// },{
	// 	name:'Русский',
	// 	title:'4'
	// },{
	// 	name:'Español',
	// 	title:'5'
	// },
	{
		name:'繁体中文',
		title:'zh_tw'
	}]
	
	const onRefClose = ()=>{
		setRefPopup(false);
	}
	const openRefPopup = ()=>{
		setRefPopup(true);
	}
	
	const openMusic = ()=>{
		if(audioRef.current.audioEl.current.paused){
			audioRef.current.audioEl.current.play();
			setMusicPlay(true);
			window.localStorage.setItem('music',1);
		}else{
			audioRef.current.audioEl.current.pause();
			setMusicPlay(false);
			window.localStorage.setItem('music',0);
		}
	}
	
	const changeLanguage= (val) => {
		i18n.changeLanguage(val);
		window.localStorage.setItem('language',val);
	};
	
	const getLanguageName = ()=>{
		let name = '';
		language.forEach((item,index)=>{
			if(item.title==i18n.language){
				name = item.name;
			}
		})
		return name;
	}
	
	React.useEffect(() => {
		if(getLocalStorage('music','')){
			setMusicAutoPlay(getLocalStorage('music',1));
		}
		if(!audioRef.current.audioEl.current.paused){
			setMusicPlay(true);
		}
	}, [])
	

    return (
		<Box sx={{
			position:'absolute',
			left:0,
			right:0,
			pt:3,
			px:{xs:1,xl:0},
			zIndex:"100"
		}}>
			<Box maxWidth="lg" sx={{
				margin:'0 auto',
				display:'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}>
				<Box sx={{
					display:'flex',
					alignItems: 'center',
				}}>
					<Box
						component="img"
						sx={{
						  width: {xs:'5rem',sm:'6rem',md:'8rem'},
						  cursor:'pointer',
						}}
						alt=""
						src={`../images/scene/logo.png`}
						onClick={()=>{navigate('/')}}
					  />
					<Box 
						hidden={!matches} 
						sx={{
						margin:{
							xs:'0 1rem',
							sm:'0 2rem'
						},
						width:'5px',
						height:'30px',
						borderRadius: '50px',
						background: 'rgba(255, 255, 255, 1)',
					}}></Box>
					<Box
						hidden={!matches}
						component="img"
						sx={{
						  width: {xs:'3rem',sm:'5rem',md:'7rem'},
						  cursor:'pointer',
						}}
						alt=""
						src={`../images/scene/polygon-logo.png`}
					  />
				</Box>
				
				<Box className="flex">
					<Box className="flex" onClick={()=>{setOpen(true)}} sx={{
						mr:{
							xs:1,
							md:2
						},
						cursor:'pointer',
						borderRadius:'50%',
						width:{
							xs:'2.2rem',
							sm:'2.5rem'
						},
						height:{
							xs:'2.2rem',
							sm:'2.5rem'
						},
						bgcolor:'rgba(168, 5, 255, 0.5)',
						border:'4px solid rgba(255, 255, 255, 0.5)',
					}}>
						<Box
							component="img"
							sx={{
							  width:'80%',
							  cursor:'pointer',
							  borderRadius:'50%',
							}}
							alt=""
							src={`../images/lang.png`}
						  />
					</Box>
					<ConnectWallet/>
					<Box className="flex" onClick={()=>{openRefPopup()}} sx={{
						ml:{
							xs:1,
							md:2
						},
						cursor:'pointer',
						borderRadius:'50%',
						width:{
							xs:'2.2rem',
							sm:'2.5rem'
						},
						height:{
							xs:'2.2rem',
							sm:'2.5rem'
						},
						bgcolor:'rgba(168, 5, 255, 0.5)',
						border:'4px solid rgba(255, 255, 255, 0.5)',
					}}>
						<Box
							component="img"
							sx={{
							  width:'80%',
							  cursor:'pointer',
							  borderRadius:'50%',
							}}
							alt=""
							src={`../images/share.png`}
						  />
					</Box>
					<Box
						component="img"
						sx={{
						  height: {xs:'1.2rem',sm:'1.5rem',md:'1.8rem','lg':'2rem'},
						  cursor:'pointer',
						  ml:{
						  	xs:1,
						  	md:2
						  },
						  mr:0.5
						}}
						alt=""
						src={`../images/${musicPlay?'mute':'music'}.png`}
						onClick={()=>{openMusic()}}
					  />
				   <ReactAudioPlayer
						  src="../../zoo.mp3"
						  autoPlay={musicAutoPlay==1}
						  loop={true}
						  ref={audioRef}
						/>
				</Box>
			</Box>
			
				
			
			<Dialog onClose={()=>{setOpen(false)}} open={open}>
			      <DialogTitle>{t('header.switchLanguage')}</DialogTitle>
			      <List sx={{ pt: 0}}>
			        {language.map((item) => (
			          <ListItem button onClick={() => {changeLanguage(item.title);setOpen(false)}} key={item.title}>
			            <ListItemText primary={item.name} />
			          </ListItem>
			        ))}
			    </List>
			</Dialog>
			
			<RefPopup isOpen={refPopup} onClose={onRefClose} />
			
		</Box>
    );
};

